/* global Component, wrapPost, _ */
class awesonLinkToComponent extends Component{

    static name() {
        return "awesonLinkToComponent";
    }

    static componentName() {
        return "awesonLinkToComponent";
    }

    getWatch(){
        return {
            'filters': function () {
                if (this.filters != null && Object.keys(this.filters).length >= 0) {
                    this.loadLinkToData();
                }
            },
            'searchValue':function () {
                this.suggestionsList = [];
                if(this.searchValue.length > 1)
                    this.findSuggestions();
            }
        };
    }

    getProps() {
        return {
            formInline:{
                type:Boolean,
                default:false,
                required: false,
            },
            rowWidth:{
                type:Number,
                default:3,
                required: false,
            },
            labelWith:{
                type:Number,
                default:4,
                required: false,
            },
            inputWith:{
                type:Number,
                default:8,
                required: false,
            },
            table: {
                type: String,
                required: true,
            },
            paste: {
                type: String,
                required: true,
            },
            showField: {
                required: true,
            },
            selected: {
                type: String,
                //required: true,
            },
            delayLoad:{
                type:Boolean,
                default:false,
            },
            filters: {
                type: Object,
            },
            callback: {
                type: Function
            },
            recordKey:{
                type:String,
                default:''
            },
            rowField:{
                type:String,
                default:''
            },
            showLabel:{
                type:Boolean,
                default:false
            },
            label:{
                type:String,
                default:''
            },
            noLinked:{
                type:Boolean,
                default:false
            },
            searchValueProp:{
                type:String,
                default:""
            }
        };
    }

    data() {
        return {
            linkToData: {},
            mustUpdate: false,
            suggestionsList:[],
            selectedValue:null,
            searchValue:null
        };
    }

    created() {
        return async function () {
            if (!this.delayLoad) {
                this.loadLinkToData();
            }
        };
    }

    mounted() {
        return async function () {
            this.subscribeEvent(`clearAwesonLinkTo_${this.recordKey}__${this.table}` , this.resetSelected);
        };
    }

    getComputed(){
        return {
            containerClass:function (){
                let containerObj = {};
                containerObj["form-group"] = this.formInline;
                containerObj[`row`] = this.formInline;
                containerObj[`col-` + this.rowWidth] = this.formInline && this.rowWidth != 12;
                return containerObj;
            },
            labelClass:function (){
                let labelObj = {};
                labelObj[`col-sm-${this.labelWith}`] = true;
                return labelObj;
            },
            inputClass:function (){
                let inputObj = {};
                inputObj[`col-sm-${this.inputWith}`] = true;
                return inputObj;
            },
            OurSettingsCountry: function () {
                return this.$store.getters.getOurSettingsCountry;
            },
            storeSelected: function (){
                return this.$store.getters.getSelectLinkToValue(this.recordKey + '_' + this.table);
            },
            computerSelectedValue() {
                if(this.selected == null) {
                    this.mustUpdate = false;
                    if (this.table === 'Country' && this.storeSelected === "") {
                        return this.OurSettingsCountry;
                    } else {
                        if (this.storeSelected)
                            return this.storeSelected;
                    }
                }
                return this.selected;
            },
        };
    }

    getMethods() {
        return {
            resetSelected: this.resetSelected,
            loadLinkToData: function () {
                wrapPost(`/${this.$store.getters.getSetting.PathURL}/api/getLinkToData`, {
                    table: this.table,
                    paste: this.paste,
                    showField: this.showField,
                    filters: this.filters
                })
                    .then(response => {
                        let linkToData = Object.freeze(response.data.result);
                        linkToData = _.map(linkToData, x => {
                            return Object.assign(x.fields, x.virtualFields);
                        });
                        this.linkToData = linkToData;
                    });
            },
            getOptionLabel(option) {
                let label = "";
                if (Array.isArray(this.showField)) {
                    for (let recField of this.showField)
                        label = label + " " + option[recField];
                } else {
                    label = option[this.showField];
                }
                return label;
            },
            updateSelectLinkto: function (event) {
                let selectedValue = null;
                for (let x of this.linkToData)
                    if (x[this.paste] == event.target.value)
                        selectedValue = x[this.rowField];
                if (this.noLinked)
                    selectedValue = event.target.value;

                let data = {
                    recordKey: this.recordKey,
                    table: this.recordKey ? this.recordKey + '_' + this.table : this.table,
                    linkToData: this.linkToData.filter((x) => x[this.paste] == event.target.value),
                    linkedField: selectedValue,
                    value: event.target.value
                };

                this.$store.dispatch('updateSelectLinkto', data);
                this.$emit('update-value', event.target.value);
                this.$emit('update-value-full', data);
                this.mustUpdate = true;
            },
            selectSuggestion: function () {
                if (this.searchValue.length > 1) {
                    if (this.linkToData)
                        for (let linkObj of this.linkToData) {
                            let label = linkObj[this.showField];
                            if (Array.isArray(this.showField)) {
                                for (let recField of this.showField)
                                    label = label + " " + linkObj[recField];
                            }
                            if (label.toLowerCase() == this.searchValue) {
                                this.selectedValue = linkObj[this.paste];
                                this.updateSelectLinkto({target: {value: linkObj[this.paste]}});
                                return true;
                            }
                        }
                }
                return false;
            },
            findSuggestions: window._.debounce(this.findSuggestions, 250),
        };
    }

    resetSelected() {
        this.selectedValue = null;
        this.searchValue = '';
    }

    loadLinkToData() {
        wrapPost(`/${this.$store.getters.getSetting.PathURL}/api/getLinkToData`, {
            table: this.table,
            paste: this.paste,
            showField: this.showField,
            filters: this.filters
        })
            .then(response => {
                let linkToData = Object.freeze(response.data.result);
                linkToData = _.map(linkToData, x => {
                    return Object.assign(x.fields, x.virtualFields);
                });
                this.linkToData = linkToData;
            });
    }

    getOptionLabel(option) {
        let label = "";
        if (Array.isArray(this.showField)) {
            for (let recField of this.showField)
                label = label + " " + option[recField];
        } else {
            label = option[this.showField];
        }
        return label;
    }

    updateSelectLinkto(event) {
        let selectedValue = null;
        for (let x of this.linkToData)
            if (x[this.paste] == event.target.value)
                selectedValue = x[this.rowField];
        if (this.noLinked)
            selectedValue = event.target.value;

        let data = {
            recordKey: this.recordKey,
            table: this.recordKey ? this.recordKey + '_' + this.table : this.table,
            linkToData: this.linkToData.filter((x) => x[this.paste] == event.target.value),
            linkedField: selectedValue,
            value: event.target.value
        };

        this.$store.dispatch('updateSelectLinkto', data);
        this.$emit('update-value', event.target.value);
        this.$emit('update-value-full', data);
        this.mustUpdate = true;

    }

    selectSuggestion() {
        if (this.searchValue.length > 1) {
            if (this.linkToData)
                for (let linkObj of this.linkToData) {
                    let label = linkObj[this.showField];
                    if (Array.isArray(this.showField)) {
                        for (let recField of this.showField)
                            label = label + " " + linkObj[recField];
                    }

                    if (label.toLowerCase() == this.searchValue) {
                        this.selectedValue = linkObj[this.paste];
                        this.updateSelectLinkto({target: {value: linkObj[this.paste]}});
                        return true;
                    }
                }
        }
        return false;
    }

    findSuggestions()  {
        if (!this.selectSuggestion()) {
            let newSuggestions = [];
            if (this.searchValue.length > 1) {
                for (let linkObj of this.linkToData) {
                    let label = "";
                    if (Array.isArray(this.showField)) {
                        for (let recField of this.showField)
                            label = label + " " + linkObj[recField];
                    } else
                        label = linkObj[this.showField];

                    if (label.toLowerCase() === this.searchValue.toLocaleLowerCase()) {
                        this.selectedValue = linkObj[this.paste];
                        this.updateSelectLinkto({target: {value: linkObj[this.paste]}});
                        return;
                    }

                    if (label.toLowerCase().indexOf(this.searchValue.toLocaleLowerCase()) != -1)
                        newSuggestions.push(linkObj);
                }
                if (!this.selectedValue)
                    this.suggestionsList = newSuggestions;
            }
            if (this.noLinked)
                this.updateSelectLinkto({target: {value: this.searchValue}});
        }
    }

    getTemplate() {
        return `<div  v-bind:class="containerClass">
                    <template v-if="!formInline">
                        <template v-if="showLabel">
                            <label class="form-label" >{{tr(label)}}</label>
                        </template>
                        <input :id="recordKey+'_'+rowField+'_'+table" :key="recordKey+'_'+table" :list="recordKey+'_'+table+'suggestions'"  v-model="searchValue" class="form-control" >
                        <datalist :id="recordKey+'_'+table+'suggestions'"  class="ui-autocomplete-input mr-sm-2" >
                            <template v-for="opt in suggestionsList">
                                <option :value="getOptionLabel(opt)" />
                            </template>
                        </datalist>
                    </template>
                    <template v-else>
                        <label class="form-label" v-bind:class="labelClass">{{tr(label)}}</label>
                        <div v-bind:class="inputClass">
                            <input :id="recordKey+'_'+rowField+'_'+table" :key="recordKey+'_'+table" :list="recordKey+'_'+table+'suggestions'"  v-model="searchValue" class="form-control" >
                            <datalist :id="recordKey+'_'+table+'suggestions'"  class="ui-autocomplete-input mr-sm-2" >
                                <template v-for="opt in suggestionsList">
                                    <option :value="getOptionLabel(opt)" />
                                </template>
                            </datalist>
                        </div>
                    </template>
                </div>`;
    }

}

awesonLinkToComponent.registerComponent();